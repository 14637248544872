import { Injectable, Injector } from '@angular/core';
import { IIssue } from '@common/interfaces/issue';
import { IssueUtil } from '@ep-om/utils/issue';
import { QueryEntity } from '@datorama/akita';
import { sha1 } from 'object-hash';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { interfaceDataQueryMap } from '../interfaceDataMapping';
import { IssueTypeInterfaceQuery } from '../issueTypeInterface/issueTypeInterface.query';
import { FilterStore, FilterState } from './filter.store';

@Injectable({ providedIn: 'root' })
export class FilterQuery extends QueryEntity<FilterState> {
  any$ = (projectId: string) => this.selectEntityNotNull(projectId).pipe(
    map(filter => Object.entries(filter).filter(([key, value]) => key !== "projectId" && (value === true || value.length > 0)).length > 0),
  );
  all$ = (projectId: string) => this.selectEntityNotNull(projectId).pipe(
    distinctUntilChanged((x, y) => sha1(x) === sha1(y))
  );
  assignee$ = (projectId: string) => this.selectEntityNotNull(projectId).pipe(map(filter => filter.assignee));
  reporter$ = (projectId: string) => this.selectEntityNotNull(projectId).pipe(map(filter => filter.reporter));
  onlyMyIssue$ = (projectId: string) => this.selectEntityNotNull(projectId).pipe(map(filter => filter.onlyMyIssue));
  nearDueDate$ = (projectId: string) => this.selectEntityNotNull(projectId).pipe(map(filter => filter.nearDueDate));
  onlyNews$ = (projectId: string) => this.selectEntityNotNull(projectId).pipe(map(filter => filter.onlyNews));
  settings$ = (projectId: string) => this.selectEntityNotNull(projectId).pipe(map(filter => filter.settings));
  issueState$ = (projectId: string) => this.selectEntityNotNull(projectId).pipe(map(filter => filter.issueState));
  milestone$ = (projectId: string) => this.selectEntityNotNull(projectId).pipe(map(filter => filter.milestone));
  tag$ = (projectId: string) => this.selectEntityNotNull(projectId).pipe(map(filter => filter.tag));
  issueType$ = (projectId: string) => this.selectEntityNotNull(projectId).pipe(map(filter => filter.issueType));
  priority$ = (projectId: string) => this.selectEntityNotNull(projectId).pipe(map(filter => filter.priority));
  searchTerm$ = (projectId: string) => this.selectEntityNotNull(projectId).pipe(map(filter => filter.searchTerm));

  constructor(
    protected store: FilterStore,
    private _typeInterfaceQuery: IssueTypeInterfaceQuery,
    private injector: Injector
  ) {
    super(store);
  }

  selectEntityNotNull(projectId: string) {
    return this.selectEntity(projectId).pipe(filter(filter => !!filter));
  }

  // keyFrasesPartsToIgnore - an array that contains parts of the words in order to filter interfaces data keys:
  //      (for example id, projectId, createdAt etc.)
  // keyRegexToIgnore - optional, an additional interfaceData keys filter:
  //      (for example /^is[A-Z]/g will filter all the interfaceData keys starting with is + an uppercased letter)

  isFoundInterfaceFormData(issue: IIssue, frasesPartsToIgnoreInKeys: string[], regexToIgnoreInKeys?: RegExp) {
    const searchTerm = this.getEntity(issue.projectId).searchTerm.toLowerCase();
    if (!searchTerm) return true;
    const isMatchTerm = IssueUtil.searchString(issue.title + " " + issue.protocolNumber.toString(), searchTerm);
    if (isMatchTerm) return true;

    let isFound = false;
    const interfaces = this._typeInterfaceQuery.getByIssueTypeId(issue.typeId)
      .filter(i => this.getEntity(issue.projectId).settings.search?.some(intToSearch => intToSearch.interfaceName === i.interfaceName && intToSearch.typeInterfaceName === i.name));
      //.filter(i => this.getEntity(issue.projectId).settings.search?.includes(i.interfaceName));
    
    for (const i of interfaces) {
      if (isFound) {
        break;
      }
      const query = this.injector.get(interfaceDataQueryMap[i.interfaceName]);
      if (!query) {
        continue;
      }
      const interfaceData = query.getByIssueIdAndTypeInterfaceName(issue.id, i.name);
      if (interfaceData.length === 0) {
        continue;
      }
      for (let iData of interfaceData) {
        const keys = Object.keys(iData).filter(key => !frasesPartsToIgnoreInKeys.includes(key));
        for (const key of keys) {
          if (!Array.isArray(iData[key]) && !['string', 'number'].includes(typeof (iData[key]))) {
            continue;
          }
          isFound = !Array.isArray(iData[key]) ? iData[key]?.toString().toLowerCase().includes(searchTerm) : iData[key]?.map(v => v.toString().toLowerCase()).includes(searchTerm);
          if (isFound) {
            break;
          }
        }
      }
    }
    return isFound;
  }

}
