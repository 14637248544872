import { ID } from "./id"

export enum ImplementationMode {
  SINGLE = '1',
  MULTI = '2',
  MULTIFIXED = '3',
  HISTORY = '4'
}

export const InterfaceName = {
  CLIENTE: "1",
  CONTRATTO: "2",
  ASSETS: "3",
  RIFERIMENTI: "4",
  OLO_REQUEST: "5",
  CONDOMINIO: "6",
  SERVIZIO_AGGIUNTIVO: "7",
  APPARATO: "8",
  CHECKLIST: "9",
  RICHIESTA_PERMESSO: "10",
  RISPOSTA: "11",
  ACQUISIZIONE_NEGATIVA: "12",
  ACCETTAZIONE_NEGATIVA: "13",
  APPUNTAMENTO: "14",
  ESPLETAMENTO_NEGATIVO: "15",
  ESPLETAMENTO_POSITIVO: "16",
  ANNULLATO: "17",
  OLO_COMMUNICATION: "18",
  OLO_SOSPENSIONE: "19",
  EP_TICKET: "20",
  ORDINE_OLO: "21",
  RISPOSTA_SOLLECITO: "22",
  INSTALLAZIONE: "23",
  OLO_RISOLUZIONE: "24",
  GENERIC_SUSPENSION: "25",
  OLO_RISORSA: "26",
  OLO_RIFERIMENTI_UI: "27",
  SUPPORT: "28", //editable
  OLO_DEACTIVATION: "29", //readonly
  OLO_DEACTIVATION_VALIDATION: "30", //readonly
  OLO_DEACTIVATION_PHYSIC: "31", //readonly
  GENERIC_KO: "32", //hidden
  MAIL_GTW_SOURCE: "33", //readonly
  GR_RESOURCES: "gr_resources", //readonly
  GR_TASKS: "gr_tasks", //readonly
  GR_RESOURCES_MAP: "gr_resources_map", //readonly
  ACTIVATION_REQUEST: "activation_request",
  ASSURANCE_TICKET: "assurance_ticket",
  GENERIC_OK: "generic_ok",
  NETWORK_ITEMS: "network_items",
  FAULT_MANAGEMENT: "fault_management",
  GENERIC_APPOINTMENT: "generic_appointment",
  OLO_REQUEST_OF: "olo_request_of",
  OLO_RISORSA_PNI: "olo_resource_pni",
  ESPLETAMENTO_POSITIVO_OF: "espletamento_positivo_of",
  ISSUE_LINK: "issue_link",
  ISSUE_LINKED: "issue_linked",
  SLA_EDITOR: "sla_editor",
} as const;

export type InterfaceNameKey = keyof typeof InterfaceName;
export type InterfaceNameValue = (typeof InterfaceName)[InterfaceNameKey] | string & {};

export const InterfaceNameEntries = Object.entries(InterfaceName) as [InterfaceNameKey, InterfaceNameValue][];

export const INTERFACE_GENERIC_PROPERTIES = ['projectId', 'issueId', 'archivedAt', 'version', 'isValid', 'id', 'deletedAt',
  'updatedAt', 'createdAt', 'createdBy', 'lastUpdatedBy'];

export const getInterfaceNameKeyFromValue = (value: InterfaceNameValue): any => {
  const key = Object.entries(InterfaceName).find(c => c[1] === value);
  return (key ? key[0] : null);
};

export const getTranslationInterfaceName = (interfaceName: InterfaceNameValue): any => {
  return `INTERFACES._TITLES.${getInterfaceNameKeyFromValue(interfaceName)?.toUpperCase() ?? interfaceName}`;
};

export const getTranslationInterfaceProperty = (interfaceName: InterfaceNameValue, prop: string): any => {
  return INTERFACE_GENERIC_PROPERTIES.includes(prop) ?
    `INTERFACES._GENERIC.${prop.toUpperCase()}`
    : `INTERFACES.${getInterfaceNameKeyFromValue(interfaceName)?.toUpperCase() ?? interfaceName}.${prop.toUpperCase()}`
};

export const getInterfaceNameFromKey = (key: InterfaceNameKey | string & {}) => {
  const found = Object.entries(InterfaceName).find(([k, v]) => k === key)
  if (!found) {
    return null;
  }
  return {
    key: found[0],
    value: found[1],
  }
};

export const getInterfaceNameFromValue = (value: InterfaceNameValue | string & {}) => {
  const found = Object.entries(InterfaceName).find(([k, v]) => v === value)
  if (!found) {
    return null;
  }
  return {
    key: found[0],
    value: found[1],
  }
};

// Modificare con cautela!
export interface InterfaceOption {
  interfaceName: InterfaceNameValue;
  typeInterfaceName: string;
}

export interface IIssueTypeInterface {
  id: ID;
  order: number;
  implementationMode: ImplementationMode;
  interfaceName: InterfaceNameValue;
  label: string;
  name: string;
  issueTypeId: ID;
  createdAt: string;
  updatedAt: string;
}

export interface TabsState {
  modal: string | null;
  route: string;
  recentTabIndex: number;
}
export interface NavigationState {
  route: string;
  openedModal: string;
  issueId?: string;
  tabsState: TabsState[];
}

export function getInterfaceNameIterable(): InterfaceNameValue[] {
  return Object.values(InterfaceName);
  // const result: InterfacesValues[] = [];
  // for (const value in InterfaceName) {
  //   const index = parseInt(value);
  //   if (!isNaN(index)) {
  //     result.push(index as InterfaceName)
  //   }
  // }
  // return result;
}
