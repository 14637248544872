import { Injectable } from "@angular/core";
import { IID_SLAEDITOR } from "@common/interfaces/interfaceData";
import { AbstractInterfaceDataQuery } from "../interfaceDataModel";
import { IIDSLAEditorStore } from "./iidSLAEditor.store";

@Injectable({
  providedIn: 'root'
})
export class IIDSLAEditorQuery extends AbstractInterfaceDataQuery<IID_SLAEDITOR> {
  constructor(protected store: IIDSLAEditorStore) {
    super(store);
  }
}
