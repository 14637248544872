import { Injectable } from '@angular/core';
import { IID_SLAEDITOR } from '@common/interfaces/interfaceData';
import { ActionService } from '../action/action.service';
import { Topics } from '@common/interfaces/topics';
import { CrudService } from '../crudService';
import { ProjectEntityStoreStrategy } from '../updateStoreStrategies';
import { IIDSLAEditorStore } from './iidSLAEditor.store';
import { IIDSLAEditorQuery } from './iidSLAEditor.query';
@Injectable({
  providedIn: 'root'
})
export class IIDSLAEditorService extends CrudService<IID_SLAEDITOR, IIDSLAEditorStore, IIDSLAEditorQuery> {
  constructor(
    protected store: IIDSLAEditorStore,
    public query: IIDSLAEditorQuery,
    protected actionService: ActionService,
    protected updateStrategy: ProjectEntityStoreStrategy) {
    super(
      Topics.IID_SLAEDITOR,
      store,
      query,
      actionService,
      updateStrategy,
    );
  }

}
