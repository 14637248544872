import { IID_OL_ESPLETATO_OK, IID_OL_RIFERIMENTI, IID_OL_RIFERIMENTI_UI, IID_OL_RISORSA, IRiferimentiUiMetadata } from "@common/interfaces/interfaceData";

export type UpsertContractOrderDTO = {
  id?: number;
  fk_building_units?: number;
  contract_code?: string;
  provider?: number;
  fk_kv_contract_state?: number;
  fk_kv_connection_type?: number;
  datamodifica?: number;
  nomeoperatore?: string;
  id_task_om?: string;
  id_risorsa_om?: string;
  note?: string;
  fib_ports_id?: string;
  last_fib_port_id?: string;
}

export type DeleteContractOrderDTO = {
  id: number;
}

export type setBuildingUnitsOfPortDTO = {
  pk_building_units: string;
  pk_fib_ports: string;
  id_risorsa_om: string;
  id: string;
}

export interface UpdateBuildingUnitDTO {
  pk_building_units: string, //should be a number
  fk_prj_nodes: string, //should be a number
  unit_id?: number,
  apt_floor?: number,
  apt_number?: string,
  customer?: string,
  is_business?: boolean,
  loop_number?: string,
  nomeoperatore: string,
}

export interface IPcabNodes {
  pk_pcab_nodes: string;
  nome: string;
  fk_stato_oggetto: string;
  drawing: string;
}

export interface IPrjNodes {
  pk_prj_nodes: string;
  name: string;
  fk_pte: string
  riferimento_scala: string;
  civico: string;
  esponente: string;
  indirizzo: string;
  drawing: string;
  ui_residenziali: string;
  ui_business: string;
}

export interface IGisfoProject {
  pk_projects: string;
  name: string;
  tipo: string;
  is_master: boolean;
}

export interface IBuildingInfo {
  indirizzo: string;
  numeroCivico: string;
  pop?: IPcabNodes;
  project: IGisfoProject;
  pfs?: IPcabNodes;
  pfp?: IPcabNodes;
  esponente: string;
  pte: IPcabNodes;
  node: IPrjNodes;
  vendibilita: string;
  statoReteAb: string;
  statoRetePni: string;
  idScala: string;
  road?: IPcabNodes[];
  isSelected: boolean;
  issueId?: string;
}

export interface IFibSignal {
  pk_fib_signal: string;
  fk_building_units: string;
  name_roe: string;
  port_number_roe: string;
  id_port_roe: string;
  fiber_number_roe: number;
  name_pfs: string;
  port_number_pfs: string;
  fiber_number_pfs: string;
  name_secondary_splitter: string;
  port_number_secondary_splitter: string;
  id_port_secondary_splitter: string;
  fiber_number_secondary_splitter: number;
  owner_secondary_splitter: string;
  seriale: string;
  ratio_secondary_splitter: string;
  name_pfp: string;
  fiber_number_pfp: string;
  name_primary_splitter: string;
  port_number_primary_splitter: string;
  id_port_primary_splitter: string;
  fiber_number_primary_splitter: number;
  owner_primary_splitter: string;
  is_double_design: boolean;
}

export interface IFibPort {
  pk_fib_ports: string;
  node?: IPcabNodes;
  fk_pcab_nodes?: string;
  port_number: number;
  is_in?: boolean;
  label_splitter?: string;
}

export interface IBuildingUnits {
  pk_building_units: string;
  fk_prj_nodes: string;
  unit_id: number;
  apt_floor: number;
  apt_number: string;
  customer: string;
  contract_code?: string;
  provider?: string;
  fk_kv_contract_state?: string;
  is_business: boolean;
  fk_kv_connection_type?: string;
  id_risorsa_om?: string;
  nomeoperatore?: string;
}

export const GISFO_WS_EXCEPTION_MESSAGE_DEFAULT = 'No response from Gisfo';

export interface GisfoResponseData {
  gisfoData: {
    signal: IFibSignal,
    riferimentiUi: IRiferimentiUiMetadata
  }, //dati dal Gisfo
  omData: {
    resource: IID_OL_RISORSA,
    espletato: IID_OL_ESPLETATO_OK,
    riferimenti: IID_OL_RIFERIMENTI,
    riferimentiUi: IID_OL_RIFERIMENTI_UI
  } // dati dall'OM
}

export interface GisfoPort {
  pk_fib_ports: string,
  fk_pcab_nodes: string,
  port_name: string,
  port_state: GisfoPortState,
  fiber_number_connected: number
}

export enum GisfoPortState {
  LIBERA = 'LIBERA',
  OCCUPATA = 'OCCUPATA',
  DISMESSA_LOGICAMENTE = 'DISMESSA LOGICAMENTE',
  NON_SERVITA = 'NON SERVITA',
  RISERVATA = 'RISERVATA',
  DISPONIBILE = 'DISPONIBILE'
}

export const RIFERIMENTI_UI: {
  label: string,
  property: string,
  pipe?: Function,
  editable: boolean
}[] = [
    { label: 'ID', property: 'unitId', editable: false },
    { label: 'ID_RESOURCE', property: 'id_risorsa_om', editable: false },
    // { label: 'ADDRESS', property: 'indirizzo', editable: false },
    // { label: 'CIVIC', property: 'civico', editable: false },
    // { label: 'EXPONENT', property: 'esponente', editable: false },
    { label: 'ID_BUILDING', property: 'idBuilding', editable: false },
    { label: 'FLOOR', property: 'aptFloor', editable: true },
    { label: 'INTERNAL', property: 'aptNumber', editable: true },
    { label: 'CUSTOMER', property: 'customer', editable: true },
    { label: 'CONTRACT_CODE', property: 'contractCode', editable: false },
    // { label: 'PROVIDER', property: 'provider_value', editable: false },
    { label: 'CONTRACT_STATE', property: 'contractState', editable: false },
    { label: 'BUSINESS_UNIT', property: 'isBusiness', pipe: (v) => v ? 'YES' : 'NO', editable: false },
    { label: 'CONNECTION_TYPE', property: 'connectionType', editable: false },
    { label: 'UI_TYPE', property: 'typeUi', editable: false },
    { label: 'OPTICAL_NODE', property: 'pcab_nodes_name', editable: false },
    // { label: 'PORT', property: 'fib_ports_name', editable: false },
    { label: 'SIGNAL', property: 'typeSignal', editable: false },
  ];

export const RIFERIMENTI_UI_GISFO: {
  label: string,
  property: string,
  pipe?: Function,
}[] = [
    { label: 'PROVIDER', property: 'provider_value' },
    { label: 'PK_PCAB_NODES', property: 'pk_pcab_nodes' },
    { label: 'OPTICAL_NODE', property: 'pcab_nodes_name' },
    { label: 'SIGNAL', property: 'tipo_segnale' },
    { label: 'FK_PTE', property: 'fk_pte' },
    { label: 'DRAWING', property: 'drawing' },
    { label: 'ADDRESS', property: 'indirizzo' },
    { label: 'CIVIC', property: 'civico' },
    { label: 'EXPONENT', property: 'esponente' },
    { label: 'ID_BUILDING', property: 'id_building' },
    { label: 'CONNECTION_TYPE', property: 'connection_type' },
    { label: 'CONTRACT_STATE', property: 'contract_state' },
    { label: 'PORT', property: 'fib_ports_name' },
    { label: 'UI_TYPE', property: 'tipo_ui' },
  ];

export const FIB_SIGNAL_GISFO: {
  label: string,
  property: string,
  pipe?: Function,
}[] = [
    { label: 'NAME_ROE', property: 'name_roe' },
    { label: 'PORTA_ROE', property: 'port_number_roe' },
    { label: 'FIBER_NUM_ROE', property: 'fiber_number_roe' },
    { label: 'PFS', property: 'name_pfs' },
    { label: 'PORT_NUMBER_PFS', property: 'port_number_pfs' },
    { label: 'N_FIBER_OUT_PFS', property: 'fiber_number_pfs' },
    { label: 'SECONDARY_SPLITTER', property: 'name_secondary_splitter' },
    { label: 'PORT_OUT_SECONDARY', property: 'port_number_secondary_splitter' },
    { label: 'N_FIBER_OUT_SECONDARY', property: 'fiber_number_secondary_splitter' },
    { label: 'OWNER_SPLITTER_SECONDARY', property: 'owner_secondary_splitter' },
    { label: 'SERIALE', property: 'seriale' },
    { label: 'RATIO_SECONDARY_SPLITTER', property: 'ratio_secondary_splitter' },
    { label: 'PFP', property: 'name_pfp' },
    { label: 'N_FIBER_PFP', property: 'fiber_number_pfp' },
    { label: 'PRIMARY_SPLITTER', property: 'name_primary_splitter' },
    { label: 'PORT_OUT_PRIMARY', property: 'port_number_primary_splitter' },
    { label: 'N_FIBER_OUT_PRIMARIO', property: 'fiber_number_primary_splitter' },
    { label: 'OWNER_SPLITTER_PRIMARY', property: 'owner_primary_splitter' },
  ];

export const RIFERIMENTI: {
  label: string,
  property: string,
  pipe?: Function
}[] = [
    { label: 'BUILDING_ID', property: 'idBuilding', },
    { label: 'APARTMENT_UNIT', property: 'scalaPalazzina', },
    { label: 'MAIN_TELEPHONE_NUMBER_LINE', property: 'numeroTelefonicoPrincipaleLinea', },
    { label: 'TEST_LINE_DELIVERY', property: 'recapitoTestLinea', },
    { label: 'POP_IDENTIFIER', property: 'identificativoPop' },
    { label: 'GPON_REQUEST', property: 'gponRichiesta' },
    { label: 'SECONDARY_OPERATOR_CODE', property: 'codiceOperatoreSecondario' },
    { label: 'TOPONYMY_PARTICLE', property: 'particellaToponomastica', },
    { label: 'ADDRESS', property: 'indirizzo', },
    { label: 'BUILDING_NUMBER', property: 'numeroCivico', },
    { label: 'POP', property: 'pop', },
    { label: 'EXPONENT', property: 'esponente', },
    { label: 'REFERENCE_UI', property: 'riferimentoUi', },
    { label: 'MARKETABILITY', property: 'vendibilita', pipe: (v) => v ? 'YES' : 'NO', },
    { label: 'UI_RESIDENTIAL', property: 'uiResidenziali', },
    { label: 'UI_BUSINESS', property: 'uiBusiness', },
  ];

export const RISORSA: {
  label: string,
  property: string,
  pipe?: Function
}[] = [
    { label: 'ID', property: 'idRisorsa', },
    { label: 'NAME', property: 'namePte', },
    { label: 'N_FIBER_IN', property: 'fiberNumberPte', },
    { label: 'PORT_IN', property: 'portPte', },
    { label: 'PORT_TERMINAL_BLOCK_OUT', property: 'portPfs' },
    { label: 'N_FIBER_OUT', property: 'fiberNumberPfs' },
    { label: 'PRIMARY_SPLITTER', property: 'nameSplitterPrimario', },
    { label: 'SECONDARY_SPLITTER', property: 'nameSplitterSecondario' },
    { label: 'PORT_OUT_PRIMARY', property: 'portSplitterPrimario', },
    { label: 'PORT_OUT_SECONDARY', property: 'portSplitterSecondario', },
    { label: 'NODE_SPLITTER_PRIMARY', property: 'nodeSplitterPrimario', },
    { label: 'NODE_SPLITTER_SECONDARY', property: 'nodeSplitterSecondario', },
    { label: 'OWNER_SPLITTER_PRIMARY', property: 'ownershipSplitterPrimario', },
    { label: 'OWNER_SPLITTER_SECONDARY', property: 'ownershipSplitterSecondario' },
    { label: 'N_FIBER_IN_PRIMARY', property: 'fiberNumberSplitterPrimarioIn', },
    { label: 'N_FIBER_OUT_PRIMARIO', property: 'fiberNumberSplitterPrimario' },
    { label: 'N_FIBER_OUT_SECONDARY', property: 'fiberNumberSplitterSecondario' },
  ];

export const BUILDING_UNITS_LABELS: {
  label: string,
  property: string,
  pipe?: Function,
  editable: boolean,
  field: { type: 'input' | 'select', option?: object, translate?: boolean }
}[] = [
    { label: 'ID', property: 'unit_id', editable: false, field: { type: 'input' } },
    { label: 'ID_GISFO', property: 'pk_building_units', editable: false, field: { type: 'input' } },
    /* { label: 'ID_RESOURCE', property: 'id_risorsa_om', editable: false, field: { type: 'input' } }, */
    { label: 'ADDRESS', property: 'indirizzo', editable: false, field: { type: 'input' } },
    { label: 'CIVIC', property: 'civico', editable: false, field: { type: 'input' } },
    { label: 'EXPONENT', property: 'esponente', editable: false, field: { type: 'input' } },
    { label: 'ID_BUILDING', property: 'id_building', editable: false, field: { type: 'input' } },
    { label: 'FLOOR', property: 'apt_floor', editable: true, field: { type: 'input' } },
    { label: 'INTERNAL', property: 'apt_number', editable: true, field: { type: 'input' } },
    { label: 'CUSTOMER', property: 'customer', editable: true, field: { type: 'input' } },
    /* { label: 'CONTRACT_CODE', property: 'contract_code', editable: false, field: { type: 'input' } }, */
    /* { label: 'PROVIDER', property: 'provider_value', editable: false, field: { type: 'input' } }, */
    /* { label: 'CONTRACT_STATE', property: 'contract_state', editable: false, field: { type: 'input' } }, */
    { label: 'BUSINESS_UNIT', property: 'is_business', pipe: (v) => v ? 'YES' : 'NO', editable: false, field: { type: 'select', option: { 'YES': true, 'NO': false }, translate: true } },
    /* { label: 'CONNECTION_TYPE', property: 'connection_type', editable: false, field: { type: 'input' } }, */
    { label: 'UI_TYPE', property: 'tipo_ui', editable: false, field: { type: 'input' } },
    /* { label: 'OPTICAL_NODE', property: 'pcab_nodes_name', editable: false, field: { type: 'input' } }, */
    /* { label: 'PORT', property: 'fib_ports_name', editable: false, field: { type: 'input' } }, */
    /* { label: 'SIGNAL', property: 'tipo_segnale', editable: false, field: { type: 'input' } }, */
  ];


export interface IGisfoResource {
  fk_resource: number,
  table_name: string,
  ordine: number,
  nome: string,
  net_use: string,
  geometry: string,
  tipo_nodo_pcab: string,
  length: number,
  fiber_number: string,
  port_number: string,
  is_port_in: string,
  fk_splitter_primario: number,
  fk_splitter_secondario: number,
  fiber_logical_type: string,
  material: string,
  net_health: number,
  num_maintenance_om: number,
  datamodifica: string,
  fk_resource_parent: number,
  table_name_parent: string,
  connection_type: string,
}

export interface IGisfoKeyValue {
  key: number,
  value: string,
  table_name?: string | string[],
  is_evidence?: boolean,
}

export interface IGisfoInfoMaintenance {
  table_name: string,
  pop: string,
  tipo_risorsa: string,
  fk_entity: number,
  name: string,
  num_maintenance: number,
}

export interface IGisfoCreateNetFaultMaintenance {
  task_name: string,
  task_id: string,
  fk_kv_activity_type: number,
  open_task: boolean,
  pk_building_units: number,
  id_risorsa_om: string,
  contract_code: string,
  username: string,
  user_group: string,
  info_maintenance: IGisfoInfoMaintenance[],
};

export interface IGisfoCreateNetTestAndNetHealth {
  id_net_test_om: string,
  resource_id: number,
  activity_id: number,
  fk_kv_net_test_type: number,
  fk_kv_net_test_result: number,
  measure?: number,
  username: string,
  user_group: string,
  info_maintenance: IGisfoInfoMaintenance[],
}

export interface IGisfoCreateNetFailureFix {
  activity_id: number,
  is_complete_fix: boolean,
  id_net_failure_fix_om: string,
  resource_id_old: number,
  resource_id_new: number,
  table_name: string,
  fk_kv_net_failure_fix_type: number,
  username: string,
  user_group: string,
  info_maintenance: IGisfoInfoMaintenance[],
}

export interface IGisfoResourceActivity {
  activity_id: number,
  resource_id: number,
  table_name: string,
  fk_kv_net_failure_fix_type: number,
}

export interface IGisfoVerifyResource {
  activity_id: number,
  resource_id_old: number,
  resource_id_new: number,
  table_name: string,
  fk_kv_net_failure_fix_type: number,
}

export interface IFaultWorkorderUpdate {
  pk_workorders: string,
  fk_kv_progress_status: string,
}
export interface IFaultActivityUpdate {
  pk_prj_activities: string,
  fk_kv_progress_status: string,
}

export interface IGisfoResponceBase {
  success: boolean,
  warning: boolean,
  description: string,
  warningMessage: string,
  errorDetailList: string[],
}

export interface IGisfoResponceNetFaultMaintenance {
  workorder: {
    name: string,
    id: number,
    num_signal: number,
  },
  activities: {
    id: number,
    task_id: string,
    activity_name: string,
    fk_kv_activity_type: number,
    resources: IGisfoResource[],
  }[],
  kv_activity_type: IGisfoKeyValue[],
}

export interface IGisfoResponseNetTestAndNetHealth extends IGisfoResponceBase {
  responseHashMap: {
    net_test_message: string,
    resources: IGisfoResource[],
    kv_net_test_type: IGisfoKeyValue[],
    kv_net_test_result: IGisfoKeyValue[],
  },
}

export interface IGisfoResponceWorkOrder extends IGisfoResponceBase {
  responseHashMap: {
    message: string,
    kv_progress_status: IGisfoKeyValue[],
  }
}

export interface IGisfoResponceActivities extends IGisfoResponceBase {
  responseHashMap: {
    message: string,
    kv_progress_status: IGisfoKeyValue[],
  }
}

export interface IGisfoResponseFreeResourcesByResourceAndActivity extends IGisfoResponceBase {
  responseHashMap: {
    free_resources: {
      fk_resource: number,
      table_name: string,
      ordine: number,
      nome: string,
      net_use: string,
      geometry: string,
      tipo_nodo_pcab: string,
      length: number,
      fiber_number: number,
      port_number: number,
      is_port_in: boolean,
      fk_splitter_primario: number,
      fk_splitter_secondario: number,
      fiber_logical_type: string,
      material: string,
      net_health: string,
      num_maintenance_om: string,
      datamodifica: string
    }[],
    kv_net_failure_fix_type: IGisfoKeyValue[],
  }
}

export interface IGisfoResponseVerifyResourceByIdAndActivity extends IGisfoResponceBase {
  responseHashMap: {},
}

export interface IGisfoResponseNetFailureFix extends IGisfoResponceBase {
  responseHashMap: {
    replaced_resources: {
      old_resource: number,
      new_resource: number,
      net_health_old_resources: number,
      table_name: string
    }[],
    resources: IGisfoResource[],
    kv_net_failure_fix_type: IGisfoKeyValue[],
  }
}
